.search-container h2{
    margin: 0;
}
.search-container .new_donation{
    margin-top: 30px;
    margin-bottom: 0;
}
.search-container .new_donation label{
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
}
.new_donation  input{
    font-family: 'Montserrat';
    font-size: 13px;
    line-height: 23px;
    font-weight: 500;
}
.submit_button_1{
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}
.submit_button_1 .submit{
    font-family: 'Montserrat';
    font-weight: 500;
    background-color: #2068F3;
    padding: 10px 20px;
    border-radius: unset;
    color: #FFF;
    text-transform: unset;
  }
  .submit_button_1 .submit:hover{
    background-color: #2068F3;
  }
  .submit_button_2{
    display: flex;
    justify-content: center;
    align-items: center;
  }