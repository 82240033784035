
  .new_entry_details_title{
    margin-bottom: 30px;
  }
  .new_entry_details .new_entry_details_title h2{
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }
  .new_donation{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .new_donation .Mui-readOnly::placeholder{
    font-family: 'Montserrat';
    font-size: 13px;
    line-height: 23px;
    font-weight: 500;
  }
  .new_donation .new_label{
    font-family: 'Montserrat';
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 07px;
  }
  .new_donation label{
    font-family: 'Montserrat';
    font-size: 13px;
    line-height: 23px;
    font-weight: 500;
  }
  .new_form_group{
    display: flex;
    flex-direction: column;
  }
  .cancel_submit_button{
    display: flex;
    justify-content: flex-end;
  }
  .cancel_submit_button .cancel{
    font-family: 'Montserrat';
    font-weight: 500;
    background-color: #EB5757;
    padding: 10px 20px;
    border-radius: unset;
    color: #FFF;
    margin-right: 10px;
    text-transform: unset;

  }
  .cancel_submit_button .cancel:hover{
    background-color: #EB5757;
  }
  .cancel_submit_button .submit:hover{
    background-color: #2068F3;
  }

  .cancel_submit_button .submit{
    font-family: 'Montserrat';
    font-weight: 500;
    background-color: #2068F3;
    padding: 10px 20px;
    border-radius: unset;
    color: #FFF;
    text-transform: unset;
  }
