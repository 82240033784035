.log_in_main .MuiBox-root{
    margin: 0;
}
.log_in_main .MuiPaper-elevation6{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login_logo img{
    width: 100px;
}
.login_info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 30px 0px 0px 0px;
}
.login_info h2{
    font-family: 'Montserrat';
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin: 0px 0px 5px 0px;
}
.login_info p{
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    margin:0px;
    width: 430px;
    color: #828282;
}
.log_in_main .MuiFormControl-fullWidth label{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
}
.log_in_main .MuiFormControl-fullWidth input{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    color: #828282;
}
.log_in_main .MuiButtonBase-root{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    text-transform: unset;
    border-radius: unset;
    margin-bottom: 0;
}
.log_in_main .MuiButtonBase-root:hover{
   box-shadow: unset !important;
}